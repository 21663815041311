<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <StudentData
          :name="studentData.full_name"
          :number="studentData.student_number"
          :email="studentData.email"
        />
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newProgramme(false)"
              @click.middle="newProgramme(true)"
            >
              <md-icon>add</md-icon>
              <md-tooltip>Add Programme</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              :search="false"
              @changed="getProgrammes"
              @onShowDetails="onShowDetails"
              @onApprove="onApprove"
              @onDeny="onDeny"
              @onSendSignRequest="onSendSignRequest"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
    <div class="md-layout">
      <div class="md-layout-item md-size-100 text-center">
        <router-link
          :to="{name:'StudentResume'}"
          tag="md-button"
          class="md-primary"
        >
          Return
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';

export default {
  components: {
    VueTable,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'programme_id',
            mask: 'Programme ID',
            sortable: true,
            no_format: true,
          },
          {
            title: 'course.title',
            mask: 'course',
            sortable: true,
          },
          {
            title: 'start_date',
            mask: 'start date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'finish_date',
            mask: 'finish date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'tuition_finish_date',
            mask: 'tuition finish date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'status',
            sortable: true,
          },
          {
            title: 'class_schedule.period',
            mask: 'Period',
            sortable: true,
          },
          {
            title: 'is_signed',
            mask: 'Accepted T & C',
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Re-Send SignRequest',
            callback: 'onSendSignRequest',
            icon: 'mail',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Approve Programme',
            callback: 'onApprove',
            icon: 'thumb_up_alt',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deny Programme',
            callback: 'onDeny',
            icon: 'thumb_down_alt',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
        ],
        values: {},
      },
      studentData: {},
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getProgrammes(params) {
      this.request(`${this.$API.STUDENTS + this.$route.params.id}/${this.$API.PROGRAMMES.paginableUrl(params)}`, 'get', {}, (res) => {
        const aux = res.data;

        // Deactivate Approve/Deny Buttons for non Waiting for Approval programmes
        aux.data = aux.data.map((x) => {
          const a = x;
          if (x.status !== 'Waiting for Approval') {
            a.onApprove = x.status === 'Fully Paid';
            a.onDeny = false;
          }
          a.onSendSignRequest = !x.is_signed && this.signRequestActive;
          return a;
        });

        this.studentData = {
          full_name: aux.data[0].student.full_name,
          student_number: aux.data[0].student.student_number,
          email: aux.data[0].student.email,
        };

        this.vTable.values = aux;
      });
    },
    onSendSignRequest(programme) {
      this.fireConfirm(
        'Re-Sending SignRequest',
        'Are you sure you want to re-send a SignRequest for this programme?',
      ).then(() => {
        this.request(`${this.$API.PROGRAMMES}${programme.programme_id}/resend_sign_request`,
          'post',
          {},
          () => {
            this.fireSuccess('Email sent succesfully!');
          });
      });
    },
    newProgramme(isMiddleClick) {
      this.openLink({ name: 'NewStudentProgramme', params: { id: this.$route.params.id } }, isMiddleClick);
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'ProgrammeDetails', params: { id: item.programme_id } }, isMiddleClick);
    },
    onDelete() {
      this.fireConfirm(
        'Deleting Student',
        'Are you sure you want to delete this Programme?',
      ).then(() => this.fireSuccess('Programme deleted successfully.'));
    },
    onApprove(programme) {
      this.fireConfirm(
        'Approving Programme',
        'Are you sure you want to Approve this Programme?',
      ).then(() => {
        this.request(`${this.$API.PROGRAMMES}${programme.programme_id}/approve`, 'put', {
          approved: true,
        }, () => {
          this.fireSuccess('Programme approved succesfully!');
          // Reset Table after response
          this.$refs.vtable.init();
        });
      });
    },
    onDeny(programme) {
      this.fireConfirm(
        'Denying Programme',
        'Are you sure you want to Deny this Programme?',
      ).then(() => {
        this.request(`${this.$API.PROGRAMMES}${programme.programme_id}/approve`, 'put', {
          approved: false,
        }, () => {
          this.fireSuccess('Programme denied succesfully!');
          // Reset Table after response
          this.$refs.vtable.init();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.studentData {
  text-align: center;
  border-top: 1px solid #ccc;
  padding: 10px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;

  .data-row {
    display: flex;
    justify-content: space-around;
  }
}
</style>
